import React, {useMemo} from 'react';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';
import styled, {useTheme} from 'styled-components';
import {Icon, hexToRgb} from 'shared/helpers';
import Animation from '@cabinetsbycomputer/animation';
import AnimationData from 'assets/animations/QuickAdd.json';
import {changeColors} from 'components/customer/Product/QuickAdd/helpers/changeColors';

export const Overlay = () => {
    const {open, loading, startAnimation, onAnimationComplete, onClickHandler} =
        useQuickAddContext();
    const theme = useTheme();

    const animationData = useMemo(() => {
        const secondaryRGB = hexToRgb(theme.colors.secondary.main);
        const rgbS = secondaryRGB.split(',').map((x) => parseFloat(x) / 255);
        rgbS.push(1);

        const primaryRGB = hexToRgb(theme.colors.primary.main);
        const rgbP = primaryRGB.split(',').map((x) => parseFloat(x) / 255);
        rgbP.push(1);

        return changeColors(AnimationData, rgbP, rgbS);
    }, [theme]);

    if (open) {
        if (startAnimation) {
            return (
                <Container>
                    <LocalAnimation
                        data={animationData}
                        autoPlay={true}
                        onComplete={onAnimationComplete}
                    />
                </Container>
            );
        }

        return (
            <Container onClick={onClickHandler}>
                <ImageAndText>
                    {loading ? (
                        <CenterIcon
                            iconName="tail-spin.svg"
                            color={theme.colors.primary.main}
                        />
                    ) : (
                        <>
                            <CenterIcon iconName="search-white.svg" />
                            CLICK to CUSTOMISE
                        </>
                    )}
                </ImageAndText>
                <SubText>
                    <div>
                        or <strong>quickly add</strong> below
                    </div>
                    <ChevronContainer>
                        <ChevronDown iconName="ChevronDownWhite.svg" />
                    </ChevronContainer>
                </SubText>
            </Container>
        );
    }
};

const LocalAnimation = styled(Animation)`
    width: 4vw;
    margin: auto auto;
`;

const SubText = styled.div`
    font-size: 0.8em;
    background: rgb(var(--secondary_colour));
    color: white;
    padding-bottom: 5px;
`;

const ChevronDown = styled((props) => <Icon {...props} />)`
    width: 10px;
`;

const ChevronContainer = styled.div`
    line-height: 1px;
`;

const ImageAndText = styled.div`
    flex: 1;
    align-content: center;

    color: white;
    font-weight: bold;
`;

const CenterIcon = styled((props) => <Icon {...props} />)`
    width: 3.5vw;
    display: block;
    margin: 0 auto;
`;

const Container = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 35px;
    background: rgba(var(--secondary_colour), 0.6);
    z-index: 10;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    overflow: clip;
    cursor: pointer;
`;
